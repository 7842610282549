import { DefaultTheme } from 'styled-components';

import { defaultConfig } from './default';
import { bondConfig } from './bond';
import { nerdWalletConfig } from './nerd-wallet';
import { collegeAveConfig } from './college-ave';
import { gabrielConfig } from './gabriel';

export interface AppConfig {
  brandName: string;
  cardName: string;
  cardReissue: {
    isEnabled: boolean;
    reissueAmount?: number;
  };
  images: {
    logoDimensions: {
      height: number;
      width: number;
    };
  };
  legalese: {
    client: {
      link: string;
      text: string;
      isPdf?: boolean;
    }[];
    evolve: {
      bank: {
        link: string;
        text: string;
        isPdf?: boolean;
      };
      communications: {
        link: string;
        text: string;
        isPdf?: boolean;
      };
      privacy: {
        link: string;
        text: string;
        isPdf?: boolean;
      };
    };
    brandPrivacy: {
      link: string;
      text: string;
      isPdf?: boolean;
    };
    brandTerms: {
      link: string;
      text: string;
      isPdf?: boolean;
    };
    rewards?: {
      link: string;
      name: string;
      text: string;
      isPdf?: boolean;
    };
  };
  maxDepositTransferAmount: number;
  maxWithdrawalTransferAmount: number;
  minimumInitialDepositInDollars?: number;
  publicPath: string;
  sdaName: string;
  supportEmail: string;
  supportPhone: string;
  theme: DefaultTheme;
  gtmId?: string;
}

export const appConfig: Record<any, AppConfig> = {
  '00000000-0000-0000-0000-000000000000': bondConfig, // Live Prod Bond test acct
  '770b66df-61ca-4197-8d64-8b0467f256c3': collegeAveConfig, // Sandbox College ave
  '9e26f938-2d82-4ad6-b13a-e14966201fa0': bondConfig,
  'b235d30e-2ce3-476e-a019-833e05689d2d': bondConfig, // Sandbox Prod Bond test acct
  'c17c0500-afce-40a5-b3b4-bb77bea04417': nerdWalletConfig, // Sandbox DorkyWallet
  'c7ba4b06-3727-490c-9983-16246bfc5b30': gabrielConfig, // Gabriel Prod sandbox
  // Prod Sandobox
  'f78edf4c-f32c-44c4-84d2-8fc32ab2acec': nerdWalletConfig,

  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  default: defaultConfig,
};
