import { defaultConfig } from './default';

import { AppConfig } from '.';

export const gabrielConfig: AppConfig = {
  ...defaultConfig,

  brandName: 'Gabriel',
  cardName: 'Gabriel Money Secured Card',
  images: {
    logoDimensions: {
      height: 23,
      width: 71.67,
    },
  },
  legalese: {
    brandPrivacy: {
      isPdf: true,
      link: 'https://embedded-cbc-dev.s3.us-east-2.amazonaws.com/gabriel/Gabriel-Card-Privacy-Policy.docx.pdf',
      text: 'Privacy Policy',
    },
    brandTerms: {
      isPdf: true,
      link: 'https://embedded-cbc-dev.s3.us-east-2.amazonaws.com/gabriel/Gabriel-Card-Terms-of-Use.pdf',
      text: 'Terms of Use',
    },

    client: [
      {
        link: 'https://gabriel.money/terms-and-conditions/',
        text: 'Terms & Conditions',
      },
      {
        link: 'https://gabriel.money/privacy-policy/',
        text: 'Privacy Policy',
      },
      {
        link: 'https://embedded-cbc-dev.s3.us-east-2.amazonaws.com/gabriel/Gabriel-Card-Esign.pdf',
        text: 'E-Sign Consent',
      },
      {
        isPdf: true,
        link: '/gabriel/docs/Gabriel-Rewards-Terms-and-Conditions.pdf',
        text: 'Rewards Program Terms & Conditions',
      },
    ],
    evolve: {
      bank: {
        isPdf: true,
        link: 'https://embedded-cbc-dev.s3.us-east-2.amazonaws.com/gabriel/Evolve-Secured-Account-Charge-Card-Account-Disclosures-Agreement.pdf',
        // eslint-disable-next-line quotes
        text: `Cardholder's Agreement`,
      },
      communications: {
        isPdf: true,
        link: 'https://embedded-cbc-dev.s3.us-east-2.amazonaws.com/gabriel/Evolve-Electronic-Communication-Consent.docx.pdf',
        text: 'Electronic Communication Consent',
      },
      privacy: {
        link: 'https://www.getevolved.com/privacy-policy/',
        text: 'Privacy Policy',
      },
    },
    rewards: {
      isPdf: true,
      link: '/gabriel/docs/Gabriel-Rewards-Terms-and-Conditions.pdf',
      name: 'Gabriel',
      text: 'Rewards Program Terms & Conditions',
    },
  },
  minimumInitialDepositInDollars: 150,
  publicPath: '/gabriel',
  sdaName: 'Gabriel Security Deposit Account',
  supportEmail: 'support@gabriel.money',
  supportPhone: '(888) 601-4224',
  theme: {
    ...defaultConfig.theme,
    button: {
      ...defaultConfig.theme.button,
      primaryBackgroundColor: '#ffcc00',
      primaryFontColor: '#01428d',
    },
    optInButton: {
      ...defaultConfig.theme.optInButton,
      backgroundColorNoValue: '#ffcc00',
    },
  },
};
